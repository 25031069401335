<script>
import Vue from "vue";

export default Vue.extend({
  // Not quite rich text...
  name: "MiddleClassText",
  props: {
    text: {
      type: String,
      default: null,
      required: false,
    },
  },
  computed: {
    textSpans() {
      // Split text into spans so we can have urls showing nicely. Ultimately, this would
      // most likely be better done with a library parsing markdown.

      let spans = [];

      let urlRegex =
        /(?<![@])\b(http(s)?:\/\/)?(www\.)?([-a-zA-Z0-9:%._+~#=]{2,256}\.[a-z]{2,6})\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
      let matches = this.text.matchAll(urlRegex);
      let currentIndex = 0;
      for (const match of matches) {
        if (currentIndex < match.index) {
          spans.push({
            text: this.text.substring(currentIndex, match.index),
          });
        }
        spans.push({
          text: match[4] + (match[5] && match[5].length > 0 ? "/..." : ""),
          link: match[1] && match[1].length > 0 ? match[0] : "http://" + match[0],
        });
        currentIndex = match.index + match[0].length;
      }

      if (currentIndex < this.text.length) {
        spans.push({
          text: this.text.substring(currentIndex),
        });
      }
      return spans;
    },
  },
});
</script>

<template>
  <blockquote class="text">
    <template v-for="(span, index) in textSpans">
      <b-link v-if="span.link" :key="index" :href="span.link" target="_blank">{{
        span.text
      }}</b-link>
      <template v-else>{{ span.text }}</template>
    </template>
  </blockquote>
</template>

<style scoped lang="scss">
.text {
  white-space: pre-wrap;
  word-break: break-word;
  margin-bottom: 0;
}
</style>
