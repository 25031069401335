<script>
import IconButton from "@/components/shared/IconButton.vue";
import { canCancelLoan } from "@/helpers/permissions/loans";
import { put } from "@/requests/server";

export default {
  name: "LoanButtons",
  components: { IconButton },
  props: {
    loan: {
      type: Object,
      required: true,
    },
  },
  computed: {
    loanIsInProcess() {
      return (
        this.loan.id &&
        this.loan.id !== "new" &&
        this.loan.status !== "canceled" &&
        this.loan.status !== "rejected" &&
        this.loan.status !== "completed"
      );
    },
    canCancelLoan() {
      return canCancelLoan(this.$store.state.user, this.loan);
    },
    canAddIncident() {
      return (
        // No new incidents.
        this.loanIsInProcess &&
        this.loan.loanable.active_incidents.filter((i) => !i.id).length === 0
      );
    },
  },
  methods: {
    async cancelLoan() {
      let cancel = await this.$bvModal.msgBoxConfirm(
        "Êtes-vous sûr-e de vouloir annuler l'emprunt?",
        {
          okTitle: "Annuler l'emprunt",
          cancelTitle: "Maintenir l'emprunt",
          okVariant: "danger",
          cancelVariant: "ghost-secondary",
        }
      );
      if (!cancel) {
        return;
      }
      const { data } = await put(`loans/${this.loan.id}/cancel`, null, {
        notifications: { action: "annulation de l'emprunt" },
      });
      this.$emit("input", data);
    },
    addIncident() {
      if (!this.canAddIncident) {
        return;
      }
      this.$emit(
        "input",
        {
          ...this.loan,
          loanable: {
            ...this.loan.loanable,
            active_incidents: [
              ...this.loan.loanable.active_incidents,
              {
                status: "in_process",
                incident_type: null,
                loan_id: this.loan.id,
              },
            ],
          },
        },
        {
          refreshDashboard: false,
          freshlyLoaded: false,
        }
      );
      this.$emit("jump", "incidents");
    },
  },
};
</script>

<template>
  <div v-if="loanIsInProcess" class="loan-action-buttons">
    <icon-button
      size="sm"
      variant="outline-warning"
      icon="exclamation-triangle"
      square
      @click="addIncident"
      >Signaler un incident</icon-button
    >
    <icon-button
      size="sm"
      variant="outline-danger"
      icon="x-octagon"
      :onclick="cancelLoan"
      :disabled="!canCancelLoan"
      square
    >
      Annuler
    </icon-button>
  </div>
</template>

<style scoped lang="scss">
.loan-action-buttons {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: stretch;
  > * {
    flex-grow: 1;
  }
}
</style>
